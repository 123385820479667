<template>
  <div class="email-login">
    <div
      v-if="errorMessage.length"
      class="email-login__error"
    >
      {{ $t(errorMessage) }}
    </div>
    <base-input
      :class="{ error: errorMessage.length }"
      data-testid="email-input"
      :maxlength="50"
      :is-disabled="isLoading"
      :placeholder="$t('loginapp.enter-email')"
      type="email"
      @keyup.enter.native="sendEmail"
      @input="getValue"
      @focus="hideError"
    />
    <base-button
      :is-type="['install', 'simple']"
      data-testid="email-button"
      :has-loading="isLoading"
      @onClick="sendEmail"
    >
      {{ $t('loginSocialView.email-button') }}
    </base-button>
    <p class="email-login__text">
      {{ $t('loginSocialView.email-sentence') }}
    </p>
  </div>
</template>

<script>

export default {
    components: {
        BaseInput: () =>
            import(/* webpackChunkName: "BaseInput"*/ '@RepoComponent/BaseInput/BaseInput'),
        BaseButton: () =>
            import(/* webpackChunkName: "BaseButton"*/ '@RepoComponent/BaseButton/BaseButton'),
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        errorMessage: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            emailInput: '',
        };
    },
    computed: {
        isValidEmail() {
            const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

            return emailRegex.exec(this.emailInput);
        },
    },
    methods: {
        sendEmail() {
            if(this.isValidEmail) {
                this.$emit('sendEmail', this.emailInput);
                return;
            }

            this.$emit('showError', 'badEmail');

        },
        hideError() {
            this.$emit('showError', 'none');
        },
        getValue(value) {
            this.emailInput = value.toLowerCase();
        },
    },
};
</script>

<style src="./EmailLoginComponent.scss" lang="scss">
</style>
